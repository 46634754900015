<template>
  <div class="bills">
    <es-table class="employ-analytics" :headers="headers" :items="bills" @update="getBillsPages">
      <v-btn class="evi-button-green mr-3" v-if="hasRight('bills.configure')" @click="requisitesModal = true">
        Заполнить реквизиты
      </v-btn>
      <v-btn class="evi-button-green evi-button-green--fill mr-3" v-if="hasRight('bills.manage')" @click="createBillModal = true">
        Выставить счёт
      </v-btn>
    </es-table>
    <v-dialog
        v-model="createBillModal"
        max-width="612px"
    >
      <CreateBill @close="createBillModal = false" @update="getData"/>
    </v-dialog>
    <v-dialog
        v-model="requisitesModal"
        max-width="612px"
    >
      <EditRequisites @close="requisitesModal = false"/>
    </v-dialog>
  </div>
</template>

<script>
import CreateBill from "../../components/bills/CreateBill";
import EditRequisites from "../../components/bills/EditRequisites";
import {mapActions, mapState} from "vuex";
import {hasRight} from '@/utils/access/hasRight';

export default {
  name: "Index",
  components: {EditRequisites, CreateBill},
  data: () => ({
    createBillModal: false,
    requisitesModal: false,
    headers: [
      {
        text: 'Номер',
        value: 'bill_number',
      },
      {
        text: 'Ссылка на счёт',
        value: 'url',
      },
      {
        text: 'Клиент',
        value: 'client.name',
      },
      {
        text: 'Сделка',
        value: 'deal.name',
      },
      {
        text: 'Сумма сделки',
        value: 'deal.sum',
      },
      {
        text: 'Дата выставления счёта',
        value: 'bill_date',
      },
      {
        text: 'НДС',
        value: 'tax',
      },
    ],
  }),
  computed: {
    ...mapState("bills", ["bills",]),
  },
  methods: {
    ...mapActions({
      getBillsPages: 'bills/getBillsPages',
    }),
    getData () {
      this.getBillsPages({
        per_page: 10,
        page: 1,
      })
    },
    hasRight,
  }
}
</script>

<style scoped>

</style>
