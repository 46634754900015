<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Реквизиты</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-tabs
            v-model="tab"
            align-with-title
            class="modal-create__tabs ml-3"
        >
          <v-tabs-slider color="#44D370"></v-tabs-slider>
          <v-tab
              v-for="item in tabs"
              :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="name"
              :rules="nameRules"
          >
            <template v-slot:label>
              Наименование
              <span class="important">*</span>
            </template>
          </v-text-field>

          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="inn"
              :rules="nameRules"
          >
            <template v-slot:label>
              ИНН
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="kpp"
              :rules="nameRules"
              v-if="isCompany"
          >
            <template v-slot:label>
              КПП
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="address"
              :rules="nameRules"
          >
            <template v-slot:label>
              Адрес
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="billNumber"
              :rules="nameRules"
          >
            <template v-slot:label>
              Номер счета
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="bank"
              :rules="nameRules"
          >
            <template v-slot:label>
              Банк
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="corrNumber"
              :rules="nameRules"
          >
            <template v-slot:label>
              Корреспондентский счет
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="bik"
              :rules="nameRules"
          >
            <template v-slot:label>
              БИК
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="headFio"
              :rules="nameRules"
          >
            <template v-slot:label>
              ФИО Руководителя
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-4"
              color="#44D370"
              v-model="financeFio"
          >
            <template v-slot:label>
              ФИО Бухгалтера
            </template>
          </v-text-field>
          <small v-if="hasSeal">Файл с печатью прикреплен</small>
          <v-file-input
              chips
              class="evi-text-field mb-5"
              label="Печать организации"
              color="#44D370"
              v-model="seal"
          />
          <small v-if="hasSignature">Файл с подписью прикреплен</small>
          <v-file-input
              chips
              class="evi-text-field mb-5"
              label="Подпись руководителя"
              color="#44D370"
              v-model="signature"
          />
          <small v-if="hasFinanceSignature">Файл с подписью прикреплен</small>
          <v-file-input
              chips
              class="evi-text-field mb-5"
              label="Подпись главного бухгалтера"
              color="#44D370"
              v-model="financeSignature"
          />
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
  data: () => ({
    valid: true,
    name: '',
    inn: '',
    kpp: '',
    address: '',
    billNumber: '',
    corrNumber: '',
    bank: '',
    bik: '',
    financeFio: '',
    headFio: '',
    signature: null,
    financeSignature: null,
    seal: null,
    hasSeal: false,
    hasSignature: false,
    hasFinanceSignature: false,
    nameRules: [(v) => !!v || 'Заполните поле'],
    tabs: [
      'ИП', 'Юр.лицо',
    ],
    tab: 0,
  }),
  computed: {
    isCompany () {
      return this.tab === 1;
    }
  },
  methods: {
    ...mapActions({
      editBillsConfigure: 'bills/editBillsConfigure',
      getBillsConfigure: 'bills/getBillsConfigure',
    }),
    validate () {
      return !!this.$refs.form.validate();
    },
    create () {
      if (this.validate()) {
        let data = {
          type: this.tab+1,
          name: this.name,
          address: this.address,
          account_number: this.billNumber,
          inn: this.inn,
          kpp: this.kpp,
          bank_name: this.bank,
          corresp_account: this.corrNumber,
          bik: this.bik,
          director_name: this.headFio,
          accountant_name: this.financeFio,
        };

        let payload;

        if (this.signature || this.financeSignature || this.seal) {
          let formData = new FormData();
          if (this.seal) formData.append('stamp', this.seal);
          if (this.signature) formData.append('director_sign', this.signature);
          if (this.financeSignature) formData.append('accountant_sign', this.financeSignature);

          for (let key in data) {
            formData.append(key, data[key]);
          }

          payload = formData;
        } else {
          payload = data;
        }

        this.editBillsConfigure(payload).then(() => {
          this.$emit('close');
          this.$emit('updateClients');
        });
      }
    },
    closeDialog () {
      this.$emit('close');
      this.$refs.form.resetValidation();
    }
  },
  mounted () {
    this.getBillsConfigure().then((response) => {
      this.tab = response.type -1;
      this.name = response.name;
      this.inn = response.inn;
      this.kpp = response.type === 2 ? response.kpp : null;
      this.address = response.address;
      this.bik = response.bik;
      this.billNumber = response.account_number;
      this.corrNumber = response.corresp_account;
      this.bank = response.bank_name;
      this.financeFio = response.accountant_name;
      this.headFio = response.director_name;
      this.hasSeal = response.has_stamp;
      this.hasSignature = response.has_director_sign;
      this.hasFinanceSignature = response.has_accountant_sign;
    });
    if (this.tab === 0) this.name = 'ИП ';
  },
  watch: {
    tab (val) {
      if (!this.name) {
        if (val === 0) {
          this.name = 'ИП ';
        } else {
          this.name = '';
        }
      }
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style scoped>

</style>
